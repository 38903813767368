#MainLayout {
  height: 100vh;
}

.MainLayout_Sider {
  overflow: auto;
}

.MainLayout_Logo {
  height: 64px;
}

.MainLayout_Header {
  padding: 0 var(--ant-padding-lg);
  background-color: var(--ant-color-bg-container);
  box-shadow: var(--ant-box-shadow);
}

.MainLayout_Content {
  padding: var(--ant-padding-lg);
  overflow: auto;
}

.MainLayout_Content_Container {
  min-height: 100%;
  padding: var(--ant-padding-lg);
  border-radius: var(--ant-border-radius-lg);
  box-shadow: var(--ant-box-shadow);
  background-color: var(--ant-color-bg-container);
}
