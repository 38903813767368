.AuthLayout_Content {
  height: 100vh;
  display: flex;
  overflow: auto;
  padding: var(--ant-padding);
}

.AuthLayout_Content_Container {
  width: 100%;
  max-width: 500px;
  margin: 10% auto auto auto;
  border-radius: var(--ant-border-radius-lg);
  border: 1px solid var(--ant-color-border-bg);
  background-color: var(--ant-color-bg-container);
  padding: var(--ant-padding);
}

.AuthLayout_Logo {
  margin: 24px auto 48px;
}
