#Logo {
  display: flex;
}

.Logo_Container {
  margin: auto;
}

.Logo_Title {
  margin: 0 !important;
  color: var(--ant-color-primary) !important;
}
